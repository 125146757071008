import { getLang } from '@lingoda/env';
import { getUrl } from '@lingoda/urls';
import { type PathAttributes, UrlProvider } from './types';
import { pathTrans } from './pathTrans';

export const TEACHER_EMAIL_CONTACT = 'teacher@lingoda.com';
/**
 * teacherHomePath
 */
export const teacherHomePath = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: getUrl('teacher_home'),
});

/**
 * teacherBookingPath
 */
export const teacherBookingPath = pathTrans('teacher_booking');

/**
 * teacherBookingPaginationPath
 */
export const teacherBookingPaginationPath = pathTrans<{ startDate: string }>(
    'teacher_booking_pagination',
);

/**
 * teacherClassPath
 * @param classId string
 */
export const teacherClassPath = pathTrans<{ classId: string }>('teacher_class');

/**
 * teacherPaymentsPath
 */
export const teacherPaymentsPath = pathTrans('teacher_log_and_payments');

/**
 * teacherTermsPath
 */
export const teacherTermsPath = pathTrans<{ agreementId: string }>('teacher_terms');

/**
 * teacherViewTermsPath
 */
export const teacherViewTermsPath = pathTrans<{ agreementId: string }>('teacher_view_terms');

/**
 * teacherLearningMaterialsPath
 */
export const teacherLearningMaterialsPath = pathTrans('teacher_learning_material');

/**
 * teacherLogoutPath
 */
export const teacherLogoutPath = pathTrans('teacher_jwt_logout');

/**
 * teacherLoginPath
 */
export const teacherLoginPath = pathTrans('teacher_login');

/**
 * teacherForgotPasswordPath
 */
export const teacherForgotPasswordPath = pathTrans('teacher_forgot_password');

/**
 * teacherForgotPasswordSuccessPath
 */
export const teacherForgotPasswordSuccessPath = pathTrans('teacher_forgot_password_success');

/**
 * teacherResetPasswordPath
 */
export const teacherResetPasswordPath = pathTrans('teacher_reset_password');

/**
 * teacherSupportPath
 */
export const teacherSupportPath = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: `https://lingodateachers.elevio.help/${getLang()}`,
});

/**
 * teacherCommunityPath
 */
export const teacherCommunityPath = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: 'https://lingodateachers.bettermode.io/',
});

/**
 * teacherContactPath
 */
export const teacherContactPath = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: `mailto:${TEACHER_EMAIL_CONTACT}`,
});

export enum ProfilePages {
    Account = 'account',
    Invoice = 'invoice',
    Profile = 'profile',
    Languages = 'languages',
    Preferences = 'preferences',
    Notifications = 'notifications',
    RatingAndFeedback = 'rating',
    Certification = 'certification',
}

/**
 * teacherProfileNewPath
 */
export const teacherProfileNewPath = pathTrans<{ page: ProfilePages }>('teacher_profile_new', {
    defaultParams: { page: ProfilePages.Profile },
});

/**
 * teacherStudentLogLevelChangePath
 */
export const teacherStudentLogLevelChangePath = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: `https://lingodateachers.elevio.help/${getLang()}/articles/62-entry-level-tests`,
});

/**
 * teacherStudentLogExamplesPath
 */
export const teacherStudentLogExamplesPath = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: 'https://docs.google.com/document/d/1dHuKsMMBCVeJln6J33c68Ep4UwXnGQ9b7Lko0YoKsUk/edit',
});

/**
 * teacherCancellationPolicy
 */
export const teacherCancellationPolicy = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: 'https://lingodateachers.elevio.help/en/articles/60-cancelling-classes',
});

/**
 * teacherCancellationPolicyNursesProgram
 */
export const teacherCancellationPolicyNursesProgram = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: 'https://lingodateachers.elevio.help/en/articles/234-wie-kann-ich-meinen-kurs-absagen',
});

/**
 * teacherPresentationPath
 */
export const teacherPresentationPath = (uniqueId: string): PathAttributes => ({
    provider: UrlProvider.External,
    href: getUrl('teacher_open_pdf_uid', { uniqueId }),
});

/**
 * teacherSupportCentre
 */
export const teacherSupportCentre = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: 'https://lingodateachers.elevio.help/en/',
});
