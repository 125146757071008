import { lazy } from 'react';
import type { RouteConfigExtended } from '@lingoda/router';
import {
    teacherBookingPaginationPath,
    teacherBookingPath,
    teacherClassPath,
    teacherForgotPasswordPath,
    teacherForgotPasswordSuccessPath,
    teacherLearningMaterialsPath,
    teacherLoginPath,
    teacherLogoutPath,
    teacherPaymentsPath,
    teacherProfileNewPath,
    teacherResetPasswordPath,
    teacherTermsPath,
    teacherViewTermsPath,
} from '@lingoda/router';
import { NotFoundPage } from '@lingoda/fallbacks';

const Main = lazy(() => import('./root/Main'));
const TeacherClass = lazy(() =>
    import('./pages/TeacherClassPage').then(({ TeacherClassPage }) => ({
        default: TeacherClassPage,
    })),
);
const TeacherBookingPage = lazy(() =>
    import('./pages/TeacherBookingPage').then(({ TeacherBookingPage }) => ({
        default: TeacherBookingPage,
    })),
);
const LearningMaterials = lazy(() =>
    import('./pages/TeacherLearningMaterialsPage').then(({ TeacherLearningMaterialsPage }) => ({
        default: TeacherLearningMaterialsPage,
    })),
);
const ClassPayments = lazy(() =>
    import('./pages/TeacherClassPaymentsPage').then(({ TeacherClassPaymentsPage }) => ({
        default: TeacherClassPaymentsPage,
    })),
);
const ProfilePage = lazy(() =>
    import('./pages/TeacherProfilePage').then(({ ProfilePage }) => ({
        default: ProfilePage,
    })),
);
const AgreementsAccept = lazy(() =>
    import('@lingoda/teacher-agreements').then(({ AgreementsAccept }) => ({
        default: AgreementsAccept,
    })),
);
const AgreementsView = lazy(() =>
    import('@lingoda/teacher-agreements').then(({ AgreementsView }) => ({
        default: AgreementsView,
    })),
);

const TeacherLogout = lazy(() =>
    import('./pages/TeacherLogoutPage').then(({ TeacherLogoutPage }) => ({
        default: TeacherLogoutPage,
    })),
);

const TeacherLogin = lazy(() =>
    import('./pages/TeacherLoginPage').then(({ TeacherLoginPage }) => ({
        default: TeacherLoginPage,
    })),
);
const TeacherForgotPassword = lazy(() =>
    import('./pages/TeacherForgotPasswordPage').then(({ TeacherForgotPasswordPage }) => ({
        default: TeacherForgotPasswordPage,
    })),
);
const TeacherForgotPasswordSuccess = lazy(() =>
    import('./pages/TeacherForgotPasswordSuccessPage').then(
        ({ TeacherForgotPasswordSuccessPage }) => ({
            default: TeacherForgotPasswordSuccessPage,
        }),
    ),
);
const TeacherResetPassword = lazy(() =>
    import('./pages/TeacherResetPasswordPage').then(({ TeacherResetPasswordPage }) => ({
        default: TeacherResetPasswordPage,
    })),
);

const routes: RouteConfigExtended[] = [
    {
        component: Main,
        label: 'Main',
        config: undefined,
        routes: [
            {
                component: TeacherClass,
                path: teacherClassPath.raw,
                label: 'Teacher Class',
                config: {
                    isPublic: false,
                    tab: 'bookClasses',
                },
            },
            {
                component: TeacherBookingPage,
                path: teacherBookingPaginationPath.raw,
                label: 'Teacher Booking',
                config: {
                    isPublic: false,
                    tab: 'bookClasses',
                },
            },
            {
                component: TeacherBookingPage,
                path: teacherBookingPath.raw,
                label: 'Teacher Booking',
                config: {
                    isPublic: false,
                    tab: 'bookClasses',
                },
            },
            {
                component: ClassPayments,
                path: teacherPaymentsPath.raw,
                label: 'Teacher Payments',
                config: {
                    isPublic: false,
                    tab: 'bookClasses',
                },
            },
            {
                component: LearningMaterials,
                path: teacherLearningMaterialsPath.raw,
                label: 'Teacher Learning Materials',
                config: {
                    isPublic: false,
                    tab: 'bookClasses',
                },
            },
            {
                component: ProfilePage,
                path: teacherProfileNewPath.raw,
                label: 'Teacher Profile',
                config: {
                    isPublic: false,
                    tab: 'bookClasses',
                },
            },
            {
                component: AgreementsAccept,
                path: teacherTermsPath.raw,
                label: 'Teacher Terms',
                config: {
                    isPublic: false,
                    tab: 'bookClasses',
                },
            },
            {
                component: AgreementsView,
                path: teacherViewTermsPath.raw,
                label: 'Teacher View Terms',
                config: {
                    isPublic: false,
                    tab: 'bookClasses',
                },
            },
            {
                component: TeacherLogout,
                path: teacherLogoutPath.raw,
                label: 'Teacher Logout',
                config: {
                    isPublic: false,
                },
            },
            {
                component: TeacherLogin,
                path: teacherLoginPath.raw,
                label: 'Teacher Login',
                config: {
                    isPublic: true,
                },
            },
            {
                component: TeacherForgotPasswordSuccess,
                path: teacherForgotPasswordSuccessPath.raw,
                label: 'Teacher Forgot Password Success',
                config: {
                    isPublic: true,
                },
            },
            {
                component: TeacherForgotPassword,
                path: teacherForgotPasswordPath.raw,
                label: 'Teacher Forgot Password',
                config: {
                    isPublic: true,
                },
            },
            {
                component: TeacherResetPassword,
                path: teacherResetPasswordPath.raw,
                label: 'Teacher Reset Password',
                config: {
                    isPublic: true,
                },
            },
            {
                component: NotFoundPage,
                label: 'PageNotFound',
                config: {
                    isPublic: true,
                },
            },
        ],
    },
];

export default routes;
