import { SnackbarProvider } from '@lingoda/ui';
import { toastsVariantsMap } from '@lingoda/toasts';

export const TeacherSnackbarProvider = ({ children }: React.PropsWithChildren<unknown>) => {
    return (
        <SnackbarProvider maxSnack={3} Components={variantsMap}>
            {children}
        </SnackbarProvider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const variantsMap: Record<string, React.JSXElementConstructor<any>> = {
    ...toastsVariantsMap,
};
